import React, { useEffect, useRef } from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import backdrop from "../images/header-image-06.png";

import agmegaPDF from "../../static/Akebia-Global-Medical-Education-Grant-Application.pdf";
import iqfPDF from "../../static/ISR-Investigator-Qualification-Form.pdf";
import pfPDF from "../../static/Akebia-ISR-Proposal-Form.pdf";

const Services = ({ location }) => {

	let scrollRef1 = useRef();
	let scrollRef2 = useRef();
	let scrollRef3 = useRef();

	let curPath = undefined;

	/* Auto-scroll to Hash Link (from a different Page) */
	useEffect(
		() => {
			var hash = location.hash;
			//console.log("Hash: " + hash);
			window.requestAnimationFrame(function () {
				setTimeout(function () {
					if (hash !== "") {
						hash = hash.replace("#", "").replace("%20", " ");
						var top = 0;
						var bodyTop = document.querySelector('body').getBoundingClientRect().top;
						if (hash === 'medical-information') {
							top = scrollRef1.current.getBoundingClientRect().top;
						} else if (hash === 'medical-education-grants') {
							top = scrollRef2.current.getBoundingClientRect().top;
						} else if (hash === 'external-sponsored-research') {
							top = scrollRef3.current.getBoundingClientRect().top;
						} else {
							top = 0;
						}
						setTimeout(function () {
							var y = top - bodyTop - 70;
							//console.log("Y: " + y);
							window.scrollTo({ behavior: 'smooth', top: y });
						}, 200);
						//
						/* Intercept Footer Hash Links, Prevent Default, and Scroll to Hash */
						curPath = location.pathname;
						//console.log("curPath: " + curPath);
						var hashLink = document.querySelectorAll('.footer-hash');
						for (var i = 0; i < hashLink.length; ++i) {
							var ref = hashLink[i];
							ref.addEventListener('click', function (e) {
								var link = this.getAttribute('href');
								if (link.indexOf('#') > 0) {
									var parts = link.split('#');
									if (parts[0] === curPath) {
										e.preventDefault();
										var hashRef = document.getElementById(parts[1]);
										var bodyTop = document.querySelector('body').getBoundingClientRect().top;
										setTimeout(function () {
											var top = hashRef.getBoundingClientRect().top;
											var y = top - bodyTop - 70;
											window.scrollTo({ behavior: 'smooth', top: y });
										}, 200);
										//console.log("Hash Link is on SAME PAGE");
									}
								}
							});
						}
					} else {
						setTimeout(function () {
							var y = 0;
							//console.log("Y: " + y);
							window.scrollTo({ behavior: 'instant', top: y });
						}, 200);
					}
				}, 10)
			});
		}, [location]
	);

	return (
		<Layout contact>
			<SEO title="Services"
				description="Learn about Akebia's support for medical professionals, including medical information, medical education grants, and research grants." />
			<Hero background={backdrop}>
				<div className="col-sm-6">
					<h1 className="white">Services for Medical Professionals</h1>
				</div>
			</Hero>
			{/* Start Breadcrumbs */}
			<div className="section section-noPad">
				<div className="container breadcrumbs">
					<div className="row">
						<div className="col">
							<div className="crumb-container">
								<Link
									to="/"
								>
									Home
								</Link>
								&nbsp;&nbsp;/&nbsp;&nbsp;
								<Link
									to="/services"
									className="active"
								>
									Services
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End Breadcrumbs */}
			<div className="section services-section">
				<div className="container">
					<div className="row">
						<div ref={scrollRef1} id="medical-information" className="col-12 hash-link">
							<h2>Akebia Medical Information</h2>
						</div>
						<div className="col-sm-6">
							<p>
								Akebia Medical Affairs is committed to providing current,
								accurate, and timely information in response to genuine
								unsolicited medical or scientific inquiries regarding our
								marketed and/or investigational products. The Akebia Medical
								Information Call Center is available to assist you Monday
								through Friday from 9:00 AM to 7:00 PM ET.
							</p>
						</div>
						<div className="col-sm-6 services-section-highlight">
							<p className="no-margin">
								<strong>Phone:</strong> 1-844-445-3799
							</p>
							<p>
								<strong>Email:</strong>{" "}
								<a
									className="teal"
									href="mailto:medicalinfo@akebia.com"
									target="_blank"
									rel="noreferrer"
								>
									medicalinfo@akebia.com
								</a>
							</p>
							<p>
								<strong>
									To report an adverse event or product complaint, please call:{" "}
									<span className="no-break">1-844-445-3799,</span> Option 2.
								</strong>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col">
						<hr />
					</div>
				</div>
			</div>
			<div className="section services-section">
				<div className="container">
					<div className="row">
						<div ref={scrollRef2} id="medical-education-grants" className="col-12 hash-link">
							<h2 className="section-heading">Global Medical Education</h2>
						</div>
						<div className="col-12">
							<p>
								At Akebia, we are dedicated to supporting high-quality,
								independent, balanced and scientifically rigorous medical
								education, including accredited Continuing Medical Education
								programs, as well as other educational programs and/or the
								dissemination of educational information or materials to
								healthcare professionals, patients, and other appropriate
								audiences.
							</p>
							<p>
								<a
									className="btn btn-primary"
									href="https://app.smartsheet.com/b/form/5a8c96e0a09346a9b4d43b7840ac5d1c"
									target="_blank"
									rel="noreferrer"
								>
									Submit an Application
								</a>
							</p>
							<p>
								<strong>
									For questions, please contact the medical education grant
									administrator at{" "}
									<a
										className="teal"
										href="mailto:grants@akebia.com"
										target="_blank"
										rel="noreferrer"
									>
										grants@akebia.com
									</a>
								</strong>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row">
					<div className="col">
						<hr />
					</div>
				</div>
			</div>
			<div className="section services-section">
				<div className="container">
					<div className="row">
						<div ref={scrollRef3} id="external-sponsored-research" className="col-12 hash-link">
							<h2 className="section-heading">External Sponsored Research</h2>
						</div>
						<div className="col">
							<p>
								Akebia recognizes the importance of advancing treatment of disease, patient outcomes, and quality of healthcare through rigorous and innovative
								medical research. We seek to support non-clinical and clinical studies investigating scientific hypotheses or questions related to Akebia’s products
								and therapeutic areas of interest.
							</p>
							<p>
								There are two types of research that Akebia may provide funding and/or alternate forms of support, such as drug product and other study materials:
							</p>
							<ul>
								<li>
									Investigator-Initiated Research (IIR): studies independently designed, implemented, and conducted by external investigators, institutions, or
									research groups
								</li>
								<li>
									Collaborative Research: studies conducted jointly with external investigators, institutions, or research groups; may be initiated by either
									Akebia or the external entity
								</li>
							</ul>
							<p>
								<strong>We invite all healthcare professionals and researchers to submit their research proposals for Akebia’s consideration.</strong>
							</p>
							<p>
								<a
									className="btn btn-primary"
									href="https://app.smartsheet.com/b/form/7858f8bbb9234934b1f602d3099ca35a"
									target="_blank"
									rel="noreferrer"
								>
									Submit an Application
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Services;
